
import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route,  } from 'react-router-dom';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import ProtectedRoute from './utilities/protectedRoute';
import changeTitle from "./utilities/changeTitle";
import Loading from "./utilities/loaders/loading"
import AuthProvider from "./utilities/context/auth"
import NavPrincipal from './NavPrincipal';
import LeaksDetalle from './LeaksDetalle';
import SignIn from'./sign-in/SignIn';
import Dashboard from'./Dashboard';
import Casos from'./Casos';
import Caso from'./Caso';
import Leaks from'./Leaks';
import Ipaddresses from'./Ipaddresses';
import Personas from'./Personas';
import Empresas from'./Empresas';
import PersonasBuscar from'./PersonasBuscar';
import PersonasResultados from './PersonasResultados';
import Users from './components/managment/users/users';
import Roles from './components/managment/roles/roles';
// const PersonaResultados = lazy(() => import('./PersonaResultados'))
// const PersonasResultados = lazy(() => import('./PersonasResultados'))


function App() {
  return (
    <div className="App">
      <Suspense fallback={<Loading/>}>
        <Routes>
          <Route path="/sign-in/SignIn" element={<SignIn />} />
          <Route element={<ProtectedRoute />}>
            <Route path={"/"} element={<Dashboard />} />
            <Route path={"/Casos"} element={<Casos />} />
            <Route path={"/Usuarios"} element={<Users />} />
            <Route path={"/Roles"} element={<Roles />} />
            <Route path="/NavPrincipal" element={<NavPrincipal />}>
              <Route index element={<Caso />} /> 
              <Route path="Caso/:id" element={<Caso />} /> 
              <Route path="Leaks/:id" element={<Leaks />} /> 
              <Route path="Ipaddresses/:id" element={<Ipaddresses />} />
              <Route path="Personas/:id" element={<Personas />} />
              <Route path="Empresas/:id" element={<Empresas />} />
            </Route>
            <Route path="/PersonasBuscar" element={<PersonasBuscar />}/>
            <Route path="/PersonasResultados" element={<PersonasResultados />}/>
            <Route path="/LeaksDetalle" element={<LeaksDetalle />}/>
          </Route>
          <Route path='*' element={<ProtectedRoute/>} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
