import React, { useContext, createContext, useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingComponent from "../loaders/loadingComponent";
import Notify from "../notifications/notify";
import apiClient from "../../api/apiClient";

export const Context = createContext();

export function useAuth(){
    return useContext(Context);
}

const AuthContext = ({ children }) => {
    const navigate = useNavigate();
    const [permissions, setPermissions] = useState([]);
    const token = localStorage.getItem("token");
    const [isAuthenticated, setAuthenticate] = useState(JSON.parse(localStorage.getItem("isAuthenticated")));
    const [isLoading, setLoading] = useState(false);
    const [messageNotify, setMessageNotify] = useState("");
    const [severityNotify, setSeverityNotify] = useState("");
    const [showNotify, setShowNotify] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const showAlert = (alertMessage, alertSeverity) => {
      setMessageNotify(alertMessage);
      setSeverityNotify(alertSeverity);
      setShowNotify(true);

      setTimeout(() => {
          setMessageNotify("");
          setSeverityNotify("");
          setShowNotify(false);
      }, 5000);
    }

    const handleClick = (index, path) => {
      setSelectedIndex(index);
      if(path === '/sign-in/SignIn')
      {
        localStorage.clear()
      }
      navigate(path)
    }

    const fetchPermissions = async () => {
        try {
          // console.log({isAuthenticated})
          if(isAuthenticated){
            setLoading(true);
            const response = await apiClient._get(`/login/accesos`);
            const permisos = response.data.map((permiso) => permiso.accesoCodigo);
            setPermissions(permisos);
          }
        } catch (error) {
          console.error("Error al cargar permisos:", error);
          setPermissions([]); 
        } finally {
          setLoading(false); 
        }
      };
    
      useEffect(() => {
        // console.log({token})
        if(!token) setAuthenticate(false);
        else setAuthenticate(true);
        
        if(isAuthenticated) fetchPermissions();
      }, [isAuthenticated, token]);
    const hasPermission = (code) => permissions.includes('ADM') || permissions.includes(code);

    const context = useMemo(() => ({
      isAuthenticated,
      setAuthenticate,
      isLoading,
      setLoading,
      showAlert,
      permissions,
      hasPermission,
      selectedIndex,
      handleClick
    }));

    return(
    <Context.Provider value={context}>
        <Notify message={messageNotify} severity={severityNotify} autoHide={300} setShowNotify={setShowNotify} showNotify={showNotify}  />
        <LoadingComponent isLoading={isLoading}/>
        {children}
    </Context.Provider>);
};

export default AuthContext;