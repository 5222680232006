import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_DIDI || 'http://localhost:55251/api';

const apiClient = axios.create({
    baseURL: BASE_URL,
    // timeout: 0,
    headers: {
        'Content-Type': 'application/json',
    }
});

apiClient.interceptors.request.use(async(config) => {
    const abort = new AbortController();
    const { signal } = abort;

    const token = localStorage.getItem("token");
    if (token){
        config.headers.Authorization = `Bearer ${token}`
    }

    config.signal = signal;
    config.AbortController = abort;
    return config;
}, error => {
    return Promise.reject(error);
});

apiClient.interceptors.response.use((response) => {
    return response
}, error => {
    if ((error?.response?.status === 401 || error?.response?.status === 403) || error.code === "ERR_NETWORK"){
        localStorage.clear();
        if (error.config?.AbortController) error.config.AbortController.abort();
    }
    return Promise.reject(error);
});

const _get = async(url, config = {}) => {
    try {
        const apiRequest = await apiClient.get(url, config);
        return apiRequest;
    }
    catch(error){
        throw handleError(error);
    }
}

const _delete = async(url, config = {}) => {
    try {
        const apiRequest = await apiClient.delete(url, config);
        return apiRequest;
    }
    catch(error){
        throw handleError(error);
    }
}

const _put = async(url, data = {}, config = {}) => {
    try {
        const apiRequest = await apiClient.put(url, data, config);
        return apiRequest;
    }
    catch(error){
        throw handleError(error);
    }
}

const _post = async(url, data = {}, config = {}) => {
    try {
        const apiRequest = await apiClient.post(url, data, config);
        return apiRequest;
    }
    catch(error){
        throw handleError(error);
    }
}

const handleError = (error) => {
    return error;
}

const APICLIENT = { _get, _post, _delete, _put }

export default APICLIENT;