import { Box, Button, Chip, Dialog, DialogContent, DialogTitle, Grid2 as Grid, InputLabel, ListItem, MenuItem, OutlinedInput, Paper, Select, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import apiClient from "../../../api/apiClient";
import { useAuth } from "../../../utilities/context/auth";

export default function UserEdit(props) {
    const { showAlert } = useAuth();
    const [roles, setRoles] = useState([]);
    const [submitBtn, setSubmitBtn] = useState(false);
    const [fieldInputs, setFieldInputs] = useState({
        usuarioId: props.userEdit?.usuario.usuarioId,
        usuarioNombres: props.userEdit?.usuario.usuarioNombres ?? '',
        usuarioLogin: props.userEdit?.usuario.usuarioLogin ?? '',
        telefono: props.userEdit?.usuario.telefono ?? '',
        roles: props.userEdit?.roles ?? [],
        // activo: props.userEdit?.email ?? '',
    });

    useEffect(() => {
        const getRoles = async() => {
            const rolesRequest = await apiClient._get('/roles');
            setRoles(rolesRequest.data);
        }
        getRoles();
    }, []);

    const handleEditUsuario = async(event) => {
        setSubmitBtn(true);
        event.preventDefault();
        try {
            const editUser = await apiClient._put('/usuarios/modificar', {
                usuarioId: fieldInputs.usuarioId,
                usuarioLogin: fieldInputs.usuarioLogin,
                usuarioNombres: fieldInputs.usuarioNombres,
                usuarioClave: fieldInputs.usuarioClave,
                roles: fieldInputs.roles.map(({rolColor, ...rest}) => rest)
            });
            if(editUser.data){
                props.handleClose();
                showAlert('Usuario editado correctamente', 'success');
            } else {
                showAlert('Usuario no ha sido editado', 'error');
            }
        }
        catch(error){
            if (error.response) {
                showAlert(`Error de servidor: ${error.response.status}`, 'error');
            } else if (error.request) {
                showAlert('Error de red: No se recibió respuesta del servidor', 'error');
            } else {
                showAlert(error.message, 'error');
            }
        }
        setSubmitBtn(false);
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFieldInputs({
            ...fieldInputs,
            [name]: value,
        });
    };

    const handleSelectChange = (event) => {
        const { value } = event.target;
        const selectedRoles = roles.filter((rol) => value.includes(rol.rolId));
        setFieldInputs({
            ...fieldInputs,
            roles: selectedRoles,
        });
    };

    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Editar Usuario"}
            </DialogTitle>
            <DialogContent>
                <Grid sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Box
                        component={'form'}
                        onSubmit={handleEditUsuario}
                        sx={{ '& .MuiTextField-root': { m: 1 } }}
                    >
                        <Box 
                            sx={{
                                width: '100%',
                                // '& .MuiTextField-root': { mb: 1 },
                            }}
                        >
                            <TextField
                                id="usuarioNombres"
                                name="usuarioNombres"
                                label="Nombre Completo"
                                // disabled={true}
                                value={fieldInputs.usuarioNombres}
                                variant="outlined"
                                fullWidth
                                onChange={handleInputChange}
                            />
                            
                            <TextField
                                id="usuarioLogin"
                                name="usuarioLogin"
                                label="Correo"
                                value={fieldInputs.usuarioLogin}
                                variant="outlined"
                                fullWidth
                                onChange={handleInputChange}
                            />
                            <TextField
                                id="usuarioClave"
                                name="usuarioClave"
                                label="Clave"
                                value={fieldInputs.usuarioClave}
                                variant="outlined"
                                type="password"
                                fullWidth
                                onChange={handleInputChange}
                            />
                            <InputLabel id="roles_label">Roles</InputLabel>
                            <Select
                                labelId="roles_label"
                                id="roles"
                                name="roles"
                                multiple
                                value={fieldInputs.roles.map((rol) => rol.rolId)}
                                onChange={handleSelectChange}
                                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                renderValue={(selected) => {
                                    return <Box key={'box-selected-edit'} sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((id) => (
                                        <Chip key={id} label={roles.find(x => x.rolId === id)?.rolNombre ?? ''} />
                                    ))}
                                    </Box>
                                }}
                                >
                                {roles.map((rol, idx) => (
                                    <MenuItem key={idx} value={rol.rolId}>
                                        {rol.rolNombre}
                                    </MenuItem>
                                ))}
                            </Select>
                            {/* <DialogTitle>Permisos</DialogTitle>
                            <Paper
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexWrap: 'wrap',
                                    listStyle: 'none',
                                    p: 0.5,
                                    m: 0,
                                }}
                                component="ul"
                            >
                                {fieldInputs.roles.map((role, idx) => {
                                    return <ListItem key={idx}>
                                        <Chip label={role.name} />
                                    </ListItem>
                                })}
                            </Paper> */}
                        </Box>
                        <Button
                            sx={{ m: 1}}
                            variant="contained" 
                            color="primary"
                            type="submit"
                            disabled={submitBtn}
                            // onClick={() => props.handleEditUser(fieldInputs)}
                        >
                            Actualizar
                        </Button>
                        <Button
                            sx={{ m: 0.2 }}
                            variant="contained" 
                            color="error"
                            onClick={() => props.handleClose()}
                        >
                            Cerrar
                        </Button>
                    </Box>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};