import { Box, Button, Chip, Dialog, DialogContent, DialogTitle, Grid2 as Grid, InputLabel, ListItem, MenuItem, OutlinedInput, Paper, Select, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { useAuth } from "../../../utilities/context/auth";
import apiClient from "../../../api/apiClient";

export default function UserCreate(props) {
    const { showAlert } = useAuth();
    const [roles, setRoles] = React.useState([]);
    const [fieldInputs, setFieldInputs] = React.useState({
        id: '',
        usuarioNombres: '',
        usuarioLogin: '',
        usuarioClave: '',
        telefono:  '',
        roles: [],
        // activo: props.userEdit?.email ?? '',
    });

    useEffect(() => {
        const getRoles = async() => {
            const rolesRequest = await apiClient._get('/roles');
            setRoles(rolesRequest.data);
        }
        getRoles();
    }, [])

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFieldInputs({
            ...fieldInputs,
            [name]: value,
        });
    };

    const handleSelectChange = (event) => {
        const { value } = event.target;
        const selectedRoles = roles.filter((rol) => value.includes(rol.rolId));
        setFieldInputs({
            ...fieldInputs,
            roles: selectedRoles,
        });
    };

    const handleCrearUsuario = async(event) => {
        event.preventDefault();
        try {
            // HAY QUE HACER UN PROCESO PARA OBTENER EL AREA ACTUAL *SE PODRA ESTAR EN VARIAS Y HAY QUE HABILITARLAS*
            const createUser = await apiClient._post('/usuarios/crear', { 
                usuarioLogin: fieldInputs.usuarioLogin,
                usuarioNombres: fieldInputs.usuarioNombres,
                usuarioClave: fieldInputs.usuarioClave,
                roles: fieldInputs.roles.map(({rolColor, ...rest}) => rest)
            });
            console.log({createUser})
            if(createUser.data){
                props.handleClose();
                showAlert('Usuario creado correctamente', 'success');
            } else {
                showAlert('Usuario no ha sido creado', 'error');
            }
        }
        catch(error){
            if (error.response) {
                showAlert(`Error de servidor: ${error.response.status}`, 'error');
            } else if (error.request) {
                showAlert('Error de red: No se recibió respuesta del servidor', 'error');
            } else {
                showAlert(error.message, 'error');
            }
        }
    }

    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Crear Usuario"}
            </DialogTitle>
            <DialogContent>
                <Grid sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Box
                        component={'form'}
                        onSubmit={handleCrearUsuario}
                        sx={{ '& .MuiTextField-root': { m: 1 } }}

                    >
                        <Box 
                            // sx={{ width: '100%' }}
                            sx={{
                                width: '100%',
                                '& .MuiTextField-root': { mb: 1 },
                            }}
                        >
                            <TextField
                                required
                                fullWidth
                                id="usuarioNombres"
                                name="usuarioNombres"
                                label="Nombre Completo"
                                // disabled={true}
                                value={fieldInputs.nombreCompleto}
                                variant="outlined"
                                onChange={handleInputChange}
                            />
                            
                            <TextField
                                required
                                fullWidth
                                id="usuarioLogin"
                                name="usuarioLogin"
                                label="Correo"
                                // disabled={true}
                                value={fieldInputs.correo}
                                variant="outlined"
                                onChange={handleInputChange}
                            />
                            <TextField
                                required
                                fullWidth
                                id="usuarioClave"
                                name="usuarioClave"
                                label="Contraseña"
                                type={'password'}
                                value={fieldInputs.usuarioClave}
                                variant="outlined"
                                onChange={handleInputChange}
                            />
                            <InputLabel id="roles_label">Roles</InputLabel>
                            <Select
                                multiple
                                labelId="roles_label"
                                id="roles"
                                name="roles"
                                value={fieldInputs.roles.map((rol) => rol.rolId)}
                                onChange={handleSelectChange}
                                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                renderValue={(selected) => {
                                    return <Box key={'box-selected-create'} sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((id) => (
                                        <Chip key={id} label={roles.find(x => x.rolId === id).rolNombre ?? ''} />
                                    ))}
                                    </Box>
                                }}
                                >
                                {roles.map((rol, idx) => (
                                    <MenuItem key={idx} value={rol.rolId}>
                                        {rol.rolNombre}
                                    </MenuItem>
                                ))}
                            </Select>
                            {/* <DialogTitle>Permisos</DialogTitle>
                            <Paper
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexWrap: 'wrap',
                                    listStyle: 'none',
                                    p: 0.5,
                                    m: 0,
                                }}
                                component="ul"
                            >
                                {fieldInputs.roles.map((role, idx) => {
                                    return <ListItem key={idx}>
                                        <Chip label={role.name} />
                                    </ListItem>
                                })}
                            </Paper> */}
                        </Box>
                        <Button
                            sx={{ m: 1}}
                            variant="contained" 
                            color="primary"
                            type="submit"
                        >
                            Crear
                        </Button>
                        <Button
                            sx={{ m: 0.2 }}
                            variant="contained" 
                            color="error"
                            onClick={() => props.handleClose()}
                        >
                            Cerrar
                        </Button>
                    </Box>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};