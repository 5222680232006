import * as React from 'react';
import {useEffect, useState} from 'react';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Copyright from '../internals/components/Copyright';
import ChartUserByCountry from './ChartUserByCountry';
import CustomizedTreeView from './CustomizedTreeView';
import CustomizedDataGrid from './CustomizedDataGrid';
import HighlightedCard from './HighlightedCard';
import PageViewsBarChart from './PageViewsBarChart';
import SessionsChart from './SessionsChart';
import StatCard from './StatCard';
import apiClient from '../api/apiClient';
import { useAuth } from "../utilities/context/auth";
import { checkResponse } from '../utilities/requestsHandling';
import { Skeleton } from '@mui/material';

{/*
const data = [
  {
    title: 'Total de Leaks',
    value: '50',
    interval: 'Ultimos 30 dias',
    trend: 'up',
    data: [
     
    ],
  },
  {
    title: 'Casos Pendientes',
    value: '13',
    interval: 'Ultimos 30 dias',
    trend: 'down',
    data: [
      1640, 1250, 970, 1130, 1050, 900, 720, 1080, 900, 450, 920, 820, 840, 600, 820,
      780, 800, 760, 380, 740, 660, 620, 840, 500, 520, 480, 400, 360, 300, 220,
    ],
  },
  {
    title: 'Casos Cerrados',
    value: '37',
    interval: 'Ultimos 30 dias',
    trend: 'neutral',
    data: [
      500, 400, 510, 530, 520, 600, 530, 520, 510, 730, 520, 510, 530, 620, 510, 530,
      520, 410, 530, 520, 610, 530, 520, 610, 530, 420, 510, 430, 520, 510,
    ],
  },
]; */}

export default function MainGrid() {
  const { isLoading, setLoading, showAlert } = useAuth();
  const [data, setData] = React.useState([]);
  const [error, setError] = React.useState(null);


  useEffect( () => {
    setLoading(true);
    const fetchData =  async () => {

    try {
       const respuesta = await apiClient._get(`/leaks/resultados`);
       setData(respuesta.data);
    }
    catch (error)
    {
      setError(error.message);

      if (error.response) {
        showAlert(checkResponse(error), 'error');
      } else if (error.request) {
        showAlert('Error de red: No se recibió respuesta del servidor', 'error');
      } else {
        showAlert(error.message, 'error');
      }

      setLoading(false);
    }
    finally{
      setLoading(false);
    }
  }
  fetchData();
  }, []);

  // if (loading) return <p>Cargando...</p>;
  if(isLoading) return (
    <Grid
        container
        spacing={2}
        columns={12}
        sx={{ mb: (theme) => theme.spacing(2) }}
      >
        {Array.from({ length: data?.length || 20 }, (_, index) => (
          <Grid key={index} size={{ xs: 12, sm: 6, lg: 3 }}>
            <Skeleton id={`grid-${index}`} variant='rounded' animation="wave" width={362} height={90} />
          </Grid>
        ))}
    </Grid>
  )

  if (!data) return <p>No hay datos disponibles.</p>;

  return (
    <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
      {/* cards */}
      <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
       Resumen
      </Typography>
      <Grid
        container
        spacing={2}
        columns={12}
        sx={{ mb: (theme) => theme.spacing(2) }}
      >
        {data.map((card, index) => (
          <Grid key={index} size={{ xs: 12, sm: 6, lg: 3 }}>
            <StatCard {...card} />
          </Grid>
        ))}
{/*
        <Grid size={{ sm: 12, md: 6 }}>
          <SessionsChart />
        </Grid>
        <Grid size={{ sm: 12, md: 6 }}>
          <PageViewsBarChart />
        </Grid>
*/}
      </Grid>
 {/*     <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
        Details
      </Typography>
      <Grid container spacing={2} columns={12}>
        <Grid size={{ md: 12, lg: 9 }}>
          <CustomizedDataGrid />
        </Grid>
        <Grid size={{ xs: 12, lg: 3 }}>
          <Stack gap={2} direction={{ xs: 'column', sm: 'row', lg: 'column' }}>
            <CustomizedTreeView />
            <ChartUserByCountry />
          </Stack>
        </Grid>
      </Grid>  
      
      <Copyright sx={{ my: 4 }} />  */}
    </Box>
  );
}
