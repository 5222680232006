import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from './context/auth';

const ProtectedRoute = ({ ...dataProps }) => {
  const token = localStorage.getItem('token');
  const location = useLocation();
  const { isAuthenticated } = useAuth();
  // console.log({data})
  // console.log({dataProps})
  // console.log({token})

  // If no token, redirect to sign-in
  if (!token || !isAuthenticated) {
    localStorage.clear()
    return <Navigate to="/sign-in/SignIn" state={{ from: location }} replace />;
  }

  // If roles are specified, check user's roles
  // if (roles && !roles.includes(user?.role)) {
  //   return <Navigate to="/unauthorized" replace />;
  // }

  // Render the child routes
  return <Outlet />;
};

export default ProtectedRoute;
