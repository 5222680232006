import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Slide from "@mui/material/Slide";
import Avatar from "@mui/material/Avatar";
import apiClient from './api/apiClient';
import { checkResponse } from './utilities/requestsHandling';
import { useAuth } from './utilities/context/auth';
import { Box, Grid2 as Grid } from '@mui/material';
import { useRef } from 'react';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PersonaDetalle({ persona }) {
  const { showAlert, setLoading, isLoading } = useAuth();
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [token, setToken] = React.useState(null);
  const [blobUrl, setBlobUrl] = React.useState(null);
  const pdfContainerRef = useRef(null);

  const handleClickOpen = () => {
    setLoading(true);
    setError(null);
    const fetchData =  async () => {

    try {
      const respUpdate = await apiClient._get(`/persona/tss/update/${persona.documento}`);
      const resp = await apiClient._get(`/persona/reporte/${persona.personaId}`);
      // const resp =  await apiClient._post(`persona`,
      //   {
      //     tipoDocumento : 1,
      //     documento: cedula,
      //   }
      // );
      setData(resp.data);
    }
    catch (error)
    {
      if (error.response) {
        showAlert(checkResponse(error), 'error');
      } else if (error.request) {
        showAlert('Error de red: No se recibió respuesta del servidor', 'error');
      } else {
        showAlert(error.message, 'error');
      }

      setLoading(false);
    }
    finally{
      setLoading(false);
    }
  }
  fetchData();
  setOpen(true);
  };

  const handleClose = () => {
    if(data){
      URL.revokeObjectURL(data);
      setData(null);
    }
    setOpen(false);
  };

  const showPDF = async() => {
    let base64 = null;
    setLoading(true);
    if(!data){
      // const updatePersona = await apiClient._get(`/persona/tss/update/${persona.documento}`);
      // console.log({updatePersona});
      const resp = await apiClient._get(`/persona/reporte/${persona.personaId}`);
      base64 = resp.data;
    }
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for(let i = 0; i < byteCharacters.length; i++){
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], {type: "application/pdf"});
    const blobUrl = URL.createObjectURL(blob);
    setData(blobUrl);
    setOpen(true);
    // const container = document.getElementById('pdfContainer');
    // console.log({container})
    // if(container){
    //   const iframe = document.createElement('iframe');
    //   iframe.src = blobUrl;
    //   iframe.width = '100%';
    //   iframe.height = '100%';
    //   iframe.border = 'none';
    //   container.innerHTML = '';
    //   container.appendChild(iframe);
    // }
    setLoading(false);
    // setTimeout(() => URL.revokeObjectURL(blobUrl), 1000 * 60);
  };

  return (
    <React.Fragment>
      <Button variant="outlined" onClick={showPDF}>
        Generar reporte
      </Button>
      {data && !isLoading ? 
        (<Grid container spacing={2}>
            <Dialog
            TransitionComponent={Transition}
            onClose={handleClose}
            open={open}
            keepMounted
            fullWidth
            maxWidth='lg'
            disableAutoFocus
            aria-labelledby="customized-dialog-title"
          >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            {persona.nombrecompleto}
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={(theme) => ({
                position: 'absolute',
                right: 8,
                top: 8,
                color: theme.palette.grey[500],
              })}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent dividers>
              <iframe
                title="PDF"
                src={data}
                width="100%"
                height='600vh'
                style={{ border: "none" }}
                // onLoad={(e) => {
                //   const iframe = e.target;
                //   iframe.style.height = `${iframe.contentWindow.document.body.scrollHeight}px`;
                // }}
                />
            </DialogContent>
            {/* <DialogActions> */}
              {/* <Button variant='contained' color='warning' onClick={() => showPDF()}>
                Descargar
              </Button> */}
              {/* <Button onClick={handleClose}>
                Cerrar
              </Button> */}
            {/* </DialogActions> */}
          </Dialog>
        </Grid>) : null}
      {/* {data && data.length > 0 ? 
        (<Grid container spacing={2}>
            <Dialog
            TransitionComponent={Transition}
            onClose={handleClose}
            open={open}
            keepMounted
            aria-labelledby="customized-dialog-title"
          >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            {data[0]?.nombrecompleto}
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={(theme) => ({
                position: 'absolute',
                right: 8,
                top: 8,
                color: theme.palette.grey[500],
              })}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent dividers>
              <Grid item container>
                <Box sx={{ display: 'flex',  alignItems: 'center'}}>
                  <Box sx={{ mr: 2 }}>
                    <Avatar
                      variant='circle'
                      alt={data[0]?.nombrecompleto}
                      src={data[0]?.fotoRuta}
                      title={data[0]?.nombrecompleto}
                      sx={{ width: 100, height: 100 }}
                    />
                  </Box>
                  <Typography variant="subtitle1">
                    {data[0]?.nombrecompleto}
                  </Typography>
                  <Typography variant="subtitle2">
                    {data[0]?.documento}
                  </Typography>
                </Box>
              </Grid>
              <Typography>
                Nacionalidad: {data[0]?.nacionalidadDescripcion}
              </Typography>
              <Typography>
                Fecha Nacimiento: {data[0]?.fhNacimiento.split("T")[0]}
              </Typography>
              <Typography>
                Edad: {data[0]?.edad},&nbsp;&nbsp;{data[0]?.genero}
              </Typography>
              <Typography>
                Historico
              </Typography>
              <Typography>
                Historico
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button variant='contained' color='warning' onClick={() => showPDF()}>
                Reporte
              </Button>
              <Button onClick={handleClose}>
                Cerrar
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>) : null
      } */}
    </React.Fragment>
  );
}
