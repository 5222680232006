import * as React from 'react';
import {useEffect, useState, useCallback} from 'react';
import { alpha } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AppNavbar from './components/AppNavbar';
import Header from './components/Header';
import SideMenu from './components/SideMenu';
import AppTheme from './shared-theme/AppTheme';
import {
  chartsCustomizations,
  dataGridCustomizations,
  datePickersCustomizations,
  treeViewCustomizations, 
} from './theme/customizations';
import { DataGrid } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import { useAuth } from "./utilities/context/auth";
import apiClient from "./api/apiClient";
import { checkResponse } from "./utilities/requestsHandling";
const xThemeComponents = {
  ...chartsCustomizations,
  ...dataGridCustomizations,
  ...datePickersCustomizations,
  ...treeViewCustomizations,
};


const columns = [
  { field: 'id', headerName: '#', flex: 1},
  {
    field: 'leakContent',
    headerName: 'Contenido',
    flex : 7,
  },
  {
    field: 'leakDate',
    headerName: 'Fecha',
    flex :2,
  }, 
];


export default function LeaksDetalle(props) {
  const { isLoading, setLoading, showAlert } = useAuth();
  const [data, setData] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [valor, setValor] = useState("");

  const handleChange = (event) => {
    setValor(event.target.value);
  };


  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); 
      handleSearch(valor); 
    }
  };

  const handleSearch = useCallback( (value) => {
    setLoading(true);

    const fetchData =  async () => {

      try {
        const respuesta = await apiClient._post(`/leaks/index`, { texto : value });
        setData(respuesta.data);
      }
      catch (error)
      {
        if (error.response) {
          showAlert(checkResponse(error), 'error');
        } else if (error.request) {
          showAlert('Error de red: No se recibió respuesta del servidor', 'error');
        } else {
          showAlert(error.message, 'error');
        }
        setLoading(false);
      }
      finally{
        setLoading(false);
      }
    }
  fetchData();
  },  []);

  useEffect( () => {
    handleSearch (""); 
  }, [handleSearch]);

  return (
    <AppTheme {...props} themeComponents={xThemeComponents}>
      <CssBaseline enableColorScheme />
      <Box sx={{ display: 'flex' }}>
        <SideMenu />
        <AppNavbar />
       
        <Box
          component="main"
          sx={(theme) => ({
            flexGrow: 1,
            backgroundColor: theme.vars
              ? `rgba(${theme.vars.palette.background.defaultChannel} / 1)`
              : alpha(theme.palette.background.default, 1),
            overflow: 'auto',
          })}
        >
          <Stack
            spacing={2}
            sx={{
              alignItems: 'center',
              mx: 3,
              pb: 5,
              mt: { xs: 8, md: 0 },
            }}
          >
            <Header />
            <Box sx={{ height: '100%', width: '100%' }}>
              <TextField id="texto" label="Buscar una palabra"  variant="outlined"  sx={{ height: '100%', width: '100%' }} 
                 onChange={handleChange}
                 onKeyDown={handleKeyDown} 
                 value={valor}
                 />
              <DataGrid
                rows={data}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 25,
                    },
                  },
                }}
                pageSizeOptions={[25]}
              
                disableRowSelectionOnClick
              />
            </Box>
          </Stack>
        </Box>
      </Box>
    </AppTheme>
  );
}
