import React from 'react';

export default function Leaks() {
  return (
    <div>
      <h1>Leaks</h1>
      <p>Esta es la página de leaks.</p>
    </div>
  );
}
