import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import OutlinedInput from '@mui/material/OutlinedInput';
import apiClient from './api/apiClient';
import { useAuth } from "./utilities/context/auth";
import Alert from '@mui/material/Alert';

function ResetearClave({ open, handleClose }) {
  const { setLoading, isLoading, showAlert } = useAuth();
  const [data, setData] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [clave1, setClave1] = React.useState("");
  const [clave2, setClave2] = React.useState("");
  const [actual, setActual] = React.useState("");
  const [aprobado, setAprobado] = React.useState(null);

  const handleGuardar =  () => {
    if(clave1 !== clave2)
    {
      showAlert("Claves no coinciden!");
      return ;
    }
    setLoading(true);
    setError(null);
  
    const resetPassword =  async () => {
      try {
        const respuesta = await apiClient._post(`/usuarios/resetearclave`, 
          {
              actual : actual,
              clave1 : clave1,
              clave2 : clave2
          }
        );
        setData(respuesta.data);
        showAlert("Se ha cambiado la clave correctamente", 'sucess');
        setActual("");
        setClave1("");
        setClave2("");
      }
      catch (error)
      {
        if (error.response) {
          showAlert(`Hubo un error al cambiar la clave`, 'error');
          setActual("");
          setClave1("");
          setClave2("");
        } else if (error.request) {
          showAlert('Error de red: No se recibió respuesta del servidor', 'error');
        } else {
          showAlert(error.message, 'error');
        }
        setLoading(false);
      }
      finally{
        setLoading(false);
      }
    }
    resetPassword();
  };
  
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      // onClose={(event, reason) => {
      //   console.log({event})
      //   console.log({reason})
      //   if (reason === "backdropClick" || reason === "escapeKeyDown") {
      //     return; 
      //   }
      //   handleClose();
      // }}
      disableEnforceFocus
      disableEscapeKeyDown
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          handleClose();
        },
      }}
    >
      <DialogTitle>Restablecer contraseña</DialogTitle>
      <DialogContent
        sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}
      >
        <DialogContentText>
          Introduce tu clave de acceso actual y digita dos veces la nueva clave
        </DialogContentText>
        <OutlinedInput
          autoFocus
          required
          margin="dense"
          id="actual"
          name="actual"
          label="Contraseña actual"
          placeholder="Contraseña actual"
          value={actual}
          type="password"
          onChange={(e) => setActual(e.target.value)}
          fullWidth
        />
         <OutlinedInput
        
          required
          margin="dense"
          id="nueva1"
          name="nueva1"
          label="Nueva"
          placeholder="Nueva Clave"
          value={clave1}
           type="password"
          onChange={(e) => setClave1(e.target.value)}
          fullWidth
        />
         <OutlinedInput
       
          required
          margin="dense"
          id="nueva2"
          name="nueva2"
          label="Repeticion"
           type="password"
          placeholder="Repetir Nueva Clave"
          value={clave2}
          onChange={(e) => setClave2(e.target.value)}
          fullWidth
        />
      </DialogContent>
      <DialogActions sx={{ pb: 3, px: 3 }}>
        <Button onClick={handleClose}>Cancelar</Button>
        <Button variant="contained" onClick={handleGuardar}>
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ResetearClave.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ResetearClave;
