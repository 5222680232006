import * as React from 'react';
import { Outlet, useNavigate  } from 'react-router-dom';
import { alpha } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AppNavbar from './components/AppNavbar';
import Header from './components/Header';
import SideMenu from './components/SideMenu';
import AppTheme from './shared-theme/AppTheme';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import LeakAddIcon from '@mui/icons-material/LeakAdd';
import LanguageIcon from '@mui/icons-material/Language';
import PeopleIcon from '@mui/icons-material/People';
import BusinessIcon from '@mui/icons-material/Business';
import {
  chartsCustomizations,
  dataGridCustomizations,
  datePickersCustomizations,
  treeViewCustomizations,

} from './theme/customizations';
import {useParams} from  'react-router-dom';

const xThemeComponents = {
  ...chartsCustomizations,
  ...dataGridCustomizations,
  ...datePickersCustomizations,
  ...treeViewCustomizations,
};


export default function NavPrincipal(props) {

  const {id} = useParams();
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  // console.log({navigate})
  // console.log({id})

  
  const navigationRoutes = {
    0: `Caso/${id}`,
    1: `Leaks/${id}`,
    2: `Ipaddresses/${id}`,
    3: `Personas/${id}`,
    4: `Empresas/${id}`
  };

  const handleNavigationChange = (event, newValue) => {
    setValue(newValue);
    const route = navigationRoutes[newValue]; 
    if (route) {
      navigate(route);
    }
  };
  return (


  <AppTheme {...props} themeComponents={xThemeComponents}>
      <CssBaseline enableColorScheme />
      <Box sx={{ display: 'flex' }}>
        <SideMenu />
        <AppNavbar />
        {/* Main content */}
        <Box
          component="main"
          sx={(theme) => ({
            flexGrow: 1,
            backgroundColor: theme.vars
              ? `rgba(${theme.vars.palette.background.defaultChannel} / 1)`
              : alpha(theme.palette.background.default, 1),
            overflow: 'auto',
          })}
        >
          <Stack
            spacing={2}
            sx={{
              alignItems: 'center',
              mx: 3,
              pb: 5,
              mt: { xs: 8, md: 0 },
            }}
          >
            <Header />
            <Box sx={{ marginTop: '20px', padding: '20px' }}>
         
            <Outlet />
            </Box>

            <BottomNavigation
              showLabels
              value={value}
              onChange={handleNavigationChange}
              sx={{ position: 'fixed', bottom: 0, width: '100%' }}
            >
              <BottomNavigationAction label="Caso"  icon={<WorkOutlineIcon />} />
              <BottomNavigationAction label="Leaks" icon={<LeakAddIcon />} />
              <BottomNavigationAction label="Ipaddresses" icon={<LanguageIcon />} />
              <BottomNavigationAction label="Personas" icon={<PeopleIcon />} />
             <BottomNavigationAction label="Empresas" icon={<BusinessIcon />} />
            </BottomNavigation>
  
          </Stack>
        </Box>
      </Box>
    </AppTheme>
  );
}
