import React from 'react';

export default function Personas() {
  return (
    <div>
      <h1>Personas</h1>
      <p>Esta es la página de personas.</p>
    </div>
  );
}
