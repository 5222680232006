import * as React from 'react';
import {useEffect, useState} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import { alpha } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
// import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import AppNavbar from './components/AppNavbar';
import Header from './components/Header';
import SearchIcon from '@mui/icons-material/Search';
import SideMenu from './components/SideMenu';
import AppTheme from './shared-theme/AppTheme';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
// import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
// import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
// import styles from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { useAuth } from "./utilities/context/auth";
import apiClient from "./api/apiClient";
import { checkResponse } from "./utilities/requestsHandling";
import {
  chartsCustomizations,
  dataGridCustomizations,
  datePickersCustomizations,
  treeViewCustomizations,

} from './theme/customizations';
import { EvStationTwoTone } from '@mui/icons-material';



const xThemeComponents = {
  ...chartsCustomizations,
  ...dataGridCustomizations,
  ...datePickersCustomizations,
  ...treeViewCustomizations,
};


export default function PersonasBuscar(props) {
  const { isLoading, setLoading, showAlert } = useAuth();
  const [data, setData] = React.useState(null);
  const [error, setError] = React.useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [caso, setCaso] = useState('');
  const [documento, setDocumento] = useState('');
  const [nombres, setNombres] = useState('');
  const [apellido1, setApellido1] = useState('');
  const [apellido2, setApellido2] = useState('');
  const [genero, setGenero] = useState('');
  const [lugar, setLugar] = useState('');
  const [inicio, setInicio] = useState('');
  const [final, setFinal] = useState('');
  const [opciones, setOpciones] = useState([]);
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const handleBuscar = (event) => {
    setLoading(true)
    event.preventDefault();
    if(!documento && (!nombres || !apellido1)){
      setLoading(false);
      showAlert('Debe llenar el Documento o el nombre y apellido.', 'error');
      return;
    }

    if(documento && documento.length !== 11){
      setLoading(false);
      showAlert('Debe especificar 11 digitos.', 'error');
      return;
    }

    navigate('/PersonasResultados', {
      state: { caso, documento, nombres, apellido1,apellido2, genero, lugar, inicio, final },
    });
  };

  useEffect(() => {
    const fetchData =  async () => {
    try {
      const respuesta = await apiClient._get('/casos/ordenTodos');
      setOpciones(respuesta.data);
    }
    catch (error)
    {
      if (error.response) {
        showAlert(checkResponse(error), 'error');
      } else if (error.request) {
        showAlert('Error de red: No se recibió respuesta del servidor', 'error');
      } else {
        showAlert(error.message, 'error');
      }
      setLoading(false);
    }
    finally {
      setLoading(false);
    }
  }
    fetchData();
  }, []);

  useEffect(() => {
    if(location.state){
      setDocumento(location.state.documento);
      setNombres(location.state.nombres);
      setApellido1(location.state.apellido1);
      setApellido2(location.state.apellido2);
      setGenero(location.state.genero);
      setLugar(location.state.lugar);
      setInicio(location.state.inicio);
      setFinal(location.state.final);
    }
  },[location.state?.scaso, location.state?.sdocumento, location.state?.snombres, location.state?.sapellido1, location.state?.sapellido2, location.state?.sgenero, location.state?.slugar, location.state?.sinicio, location.state?.sfinal]);
  //IMPROVE WAY LOCATION

  
  const [lCaso, setlCaso] = React.useState('');

  const handleChange = (event) => {
    setlCaso(event.target.value);
  };

  return (
  <AppTheme {...props} themeComponents={xThemeComponents}>
      <CssBaseline enableColorScheme />
      <Box sx={{ display: 'flex' }}>
        <SideMenu />
        <AppNavbar />
        <Box
          component="main"
          sx={(theme) => ({
            flexGrow: 1,
            backgroundColor: theme.vars
              ? `rgba(${theme.vars.palette.background.defaultChannel} / 1)`
              : alpha(theme.palette.background.default, 1),
            overflow: 'auto',
          })}
        >
          <Stack
            spacing={2}
            sx={{
              alignItems: 'center',
              mx: 3,
              pb: 5,
              mt: { xs: 8, md: 0 },
            }}
          >
            <Header />
        
            <Grid container spacing={2}>
              <Card>
                  <Typography variant="subtitle2">
                      Busqueda de Personas
                  </Typography>
                  <Divider>*</Divider>
                  {/* <Grid >  */}
                  <Grid sx={{ display: 'flex', justifyContent: 'flex-start' }}> 
                    <Box
                      component="form"
                      sx={{ '& .MuiTextField-root': { m: 1} }}
                      noValidate
                      autoComplete="off"
                    >
                      <Box sx={{ width: '100%', }}>
                        <Autocomplete
                          title='Listado de casos'
                          fullWidth
                          options={opciones}
                          getOptionLabel={(option) => option.casoNombre} 
                          isOptionEqualToValue={(option, value) => option.casoId === value.casoId} 
                          id="caso"
                          renderInput={(params) => <TextField {...params} label="Identificador de caso" title='Identificador de caso' />}
                          onChange={(event, newValue) => {
                            setCaso(newValue); 
                          }}
                          sx={{ width: '100%' }}
                        />
                      </Box>
                      <div>
                        <TextField id="lblDocumento" label="Documento" title='Documento' value={documento} onChange={(e) => setDocumento(e.target.value)} variant="standard" />
                      </div>
                      <div>
                        <TextField id="lblNombres" label="Nombres" title='Nombres' variant="standard" value={nombres} fullWidth onChange={(e) => setNombres(e.target.value)} />
                      </div>
                      <div>
                        <TextField id='lblApellido1' label="Primer apellido" title='Primer apellido' variant="standard" value={apellido1} onChange={(e) => setApellido1(e.target.value)} />
                        <TextField id='lblApellido2' label="Segundo apellido" title='Segundo apellido' variant="standard" value={apellido2} onChange={(e) => setApellido2(e.target.value)}/>
                      </div>
                      <div>
                        <TextField id="lblInicial" label="Edad inicial" title='Edad Inicial' value={inicio} onChange={(e) => setInicio(e.target.value)} variant="standard" />
                        <TextField id="lblFinal" label="Edad final" title='Edad Final' variant="standard" value={final} onChange={(e) => setFinal(e.target.value)}/>
                      </div>
                      <div>
                        <TextField id="lblNac" label="Lugar de cacimiento" title='Lugar de nacimiento' value={lugar} onChange={(e) => setLugar(e.target.value)} variant="standard" />
                      </div>
                      <div style={{marginTop: 10, marginBottom: 10}}>
                        <FormControl fullWidth>
                          <InputLabel id="genero_label">Genero</InputLabel>
                          <Select
                            value={genero}
                            label="Genero"
                            title='Lista de genero'
                            onChange={(e) => setGenero(e.target.value)}
                          >
                            <MenuItem value="M" selected >Masculino </MenuItem>
                            <MenuItem value="F">Femenino</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                
                      <div>
                        <Button variant="outlined" startIcon={<SearchIcon />} onClick={handleBuscar}>
                          Buscar
                        </Button>
                      </div>
                    </Box>
                  </Grid>
                </Card>
             </Grid>
            <div>
              {alertVisible && (
                <Alert severity="warning" onClose={() => setAlertVisible(false)}>
                  {alertMessage}
                </Alert>
              )}
            </div>
          </Stack>
        </Box>
      </Box>
    </AppTheme>
  );
}