import React from "react";
import { Backdrop, CircularProgress } from "@mui/material";
// import {FormattedMessage, format} from "react-intl"
// import Swal from "sweetalert2"
// import firebase from "../../Firebase"

const Loading = (props) => {
  let { text, open, handlerBackDrop } = props;
  return (
    <Backdrop
        sx={(theme) => ({ backgroundImage: 'radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))', zIndex: theme.zIndex.drawer + 1})}
        open={true}
        onClick={handlerBackDrop}
    >
        <CircularProgress color="primary"/>
    </Backdrop>
  );
};

export default Loading;