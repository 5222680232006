import React from 'react';

export default function Empresas() {
  return (
    <div>
      <h1>Empresas </h1>
      <p>Esta es la página de la empresa.</p>
    </div>
  );
}
