import React from "react";
import { Alert } from "@mui/material";

const Notify = ({ message, severity, showNotify }) =>
{
    return <>
    {showNotify ? 
        <Alert variant="filled" style={{ position:'absolute', bottom: 0, right: 0, width: '20%', margin: 30 }} severity={severity}>
            {message}
        </Alert> : null 
    }</>;
}

export default Notify;