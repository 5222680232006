import { Box, Button, Chip, Dialog, DialogContent, DialogTitle, FormControlLabel, FormGroup, Grid2 as Grid, InputAdornment, InputLabel, ListItem, MenuItem, OutlinedInput, Paper, Select, Switch, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { useAuth } from "../../../utilities/context/auth";
import apiClient from "../../../api/apiClient";

export default function RolCreate(props) {
    const { showAlert } = useAuth();
    const [accesos, setAccesos] = React.useState([]);
    const [fieldInputs, setFieldInputs] = React.useState({
        rolId: 0,
        rolNombre: '',
        rolColor: '',
        accesos: []
    });

    useEffect(() => {
        const getPermissions = async() => {
            const accesosRequest = await apiClient._get(`/accesos`);
            setAccesos(accesosRequest.data);
        }
        getPermissions();
    }, []);

    const handleCreateRol = async(event) => {
        event.preventDefault();
        try {
            const createRol = await apiClient._post('/roles/crear', {
                rolId: fieldInputs.rolId,
                rolNombre: fieldInputs.rolNombre,
                rolColor: fieldInputs.rolColor,
                accesos: fieldInputs.accesos
            });

            if(createRol.data){
                props.handleClose();
                showAlert('Rol creado correctamente', 'success');
            } else {
                showAlert('Rol no ha sido creado', 'error');
            }
        }
        catch(error){
            console.log({error})
            if(error.response.data){
                showAlert(`${error.response.data}`,'error')
            } else if (error.response) {
                showAlert(`Error de servidor: ${error.response.status}`, 'error');
            } else if (error.request) {
                showAlert('Error de red: No se recibió respuesta del servidor', 'error');
            } else {
                showAlert(error.message, 'error');
            }
        }
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFieldInputs({
            ...fieldInputs,
            [name]: value,
        });
    };

    // const handleSelectChange = (event) => {
    //     const { value } = event.target;
    //     const selectedRoles = roles.filter((rol) => value.includes(rol.rolId));
    //     setFieldInputs({
    //         ...fieldInputs,
    //         roles: selectedRoles,
    //     });
    // };

    const handleSwitchAccesos = (event, acceso) => {
        const { checked } = event.target;

        setFieldInputs((prev) => {
            let newAccesos = [...prev.accesos];

            if(checked){
                if(!newAccesos.some(x => x.accesoId === acceso.accesoId)){
                    newAccesos.push(acceso);
                }
            } else {
                newAccesos = newAccesos.filter(x => x.accesoId !== acceso.accesoId);
            }
            return { ...prev, accesos: newAccesos };
        });
    }

    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Editar Rol"}
            </DialogTitle>
            <DialogContent>
                <Grid sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Box
                        component={'form'}
                        onSubmit={handleCreateRol}
                        sx={{ '& .MuiTextField-root': { m: 1 } }}
                    >
                        <Box 
                            sx={{
                                width: '100%',
                                // '& .MuiTextField-root': { mb: 1 },
                            }}
                        >
                            <TextField
                                id="rolNombre"
                                name="rolNombre"
                                label="Nombre del rol"
                                // disabled={true}
                                value={fieldInputs.rolNombre}
                                variant="outlined"
                                fullWidth
                                onChange={handleInputChange}
                            />
                            
                            <TextField
                                id="rolColor"
                                name="rolColor"
                                label="Color"
                                value={fieldInputs.rolColor}
                                variant="outlined"
                                fullWidth
                                onChange={handleInputChange}
                                slotProps={{
                                    input: {
                                        startAdornment: (
                                        <InputAdornment position="start">
                                            <Chip
                                                color=''
                                                // label={rolColor || 'Unknown'}
                                                sx={{
                                                    margin: '2px',
                                                    // padding: 0.5,
                                                    color: fieldInputs.rolColor ?? '#fff',
                                                    background: fieldInputs.rolColor ?? '#fff',
                                                }}
                                                />
                                        </InputAdornment>
                                        ),
                                    },
                                    }}
                            />
                            {/* <InputLabel id="roles_label">Roles</InputLabel>
                            <Select
                                labelId="roles_label"
                                id="roles"
                                name="roles"
                                multiple
                                value={fieldInputs.roles.map((rol) => rol.rolId)}
                                onChange={handleSelectChange}
                                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                renderValue={(selected) => {
                                    return <Box key={'box-selected-edit'} sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((id) => (
                                        <Chip key={id} label={roles.find(x => x.rolId === id)?.rolNombre ?? ''} />
                                    ))}
                                    </Box>
                                }}
                                >
                                {roles.map((rol, idx) => (
                                    <MenuItem key={idx} value={rol.rolId}>
                                        {rol.rolNombre}
                                    </MenuItem>
                                ))}
                            </Select> */}
                            <DialogTitle>Accesos</DialogTitle>
                            <Paper
                                sx={{
                                    display: 'flex',
                                    // justifyContent: 'center',
                                    flexWrap: 'wrap',
                                    listStyle: 'none',
                                    padding: {
                                        paddingTop: 4,
                                        paddingBottom: 4,
                                        paddingLeft: 15,
                                        paddingRight: 1
                                    },
                                    margin: {
                                        marginTop: -10,
                                        marginBottom: 10
                                    },
                                }}
                                component="ul"
                            >
                                <FormGroup>
                                    {accesos.map((acceso, idx) => {
                                        return <FormControlLabel
                                            key={`label-${acceso.accesoCodigo}`}
                                            control={
                                                    <Switch
                                                        key={acceso.accesoId}
                                                        name={acceso.accesoCodigo}
                                                        checked={fieldInputs.accesos?.some(x => x.accesoId === acceso.accesoId)}
                                                        onChange={(e) => handleSwitchAccesos(e, acceso)}
                                                    />
                                                }
                                                label={`${acceso.accesoNombre}`}
                                            />
                                        })
                                    }
                                </FormGroup>
                                {/* {accesos.map((acceso, idx) => {
                                    return <ListItem key={idx}>
                                        <Chip label={acceso.accesoNombre} />
                                    </ListItem>
                                })} */}
                            </Paper>
                        </Box>
                        <Button
                            sx={{ m: 1}}
                            variant="contained" 
                            color="primary"
                            type="submit"
                            // onClick={() => props.handleEditUser(fieldInputs)}
                        >
                            Actualizar
                        </Button>
                        <Button
                            sx={{ m: 0.2 }}
                            variant="contained" 
                            color="error"
                            onClick={() => props.handleClose()}
                        >
                            Cerrar
                        </Button>
                    </Box>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};