import * as React from 'react';
import { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { alpha } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import AppNavbar from './components/AppNavbar';
import Header from './components/Header';
import SideMenu from './components/SideMenu';
import AppTheme from './shared-theme/AppTheme';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import { Avatar, Skeleton } from '@mui/material';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import {
  chartsCustomizations,
  dataGridCustomizations,
  datePickersCustomizations,
  treeViewCustomizations,
} from './theme/customizations';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import apiClient from "./api/apiClient";
import { useAuth } from "./utilities/context/auth";
import { checkResponse } from './utilities/requestsHandling';

function statusColor(status) {
  const colors = {
    1: 'success',
    2: 'default',
  };

  return <Chip label={status === 1 ? 'Abierto': 'Cerrado'} color={colors[status]} size="small" />;
}

const xThemeComponents = {
  ...chartsCustomizations,
  ...dataGridCustomizations,
  ...datePickersCustomizations,
  ...treeViewCustomizations,
};

export default function CasosAll(props) {
  const { setLoading, showAlert, isLoading } = useAuth();
  const [data, setData] = React.useState(null);

  useEffect( () => {
    setLoading(true);
    const fetchData =  async () => {

    try {
      const respuesta = await apiClient._get(`/casos/ordenTodos`);
      setData(respuesta.data);
    }
    catch (error)
    {
      if (error.response) {
        showAlert(checkResponse(error), 'error');
      } else if (error.request) {
        showAlert('Error de red: No se recibió respuesta del servidor', 'error');
      } else {
        showAlert(error.message, 'error');
      }
      setLoading(false);
    }
    finally{
      setLoading(false);
    }
  }
  fetchData();
  }, []);

  const [casoValor, setCasoValor] = useState('');

  const navigateD = useNavigate(); 
  const handleCasoDetalle = (id) => {
    navigateD(`/NavPrincipal/Caso/${id}`); 
  };

  const handleCrear = () => {
    navigateD(`/NavPrincipal/CasoCrear`); 
  };

  return (
  <AppTheme {...props} themeComponents={xThemeComponents}>
      <CssBaseline enableColorScheme />
      <Box sx={{ display: 'flex' }}>
        <SideMenu />
        <AppNavbar />
        <Box
          component="main"
          sx={(theme) => ({
            flexGrow: 1,
            backgroundColor: theme.vars
              ? `rgba(${theme.vars.palette.background.defaultChannel} / 1)`
              : alpha(theme.palette.background.default, 1),
            overflow: 'auto',
          })}
        >
          <Stack
            spacing={2}
            sx={{
              alignItems: 'center',
              mx: 3,
              pb: 5,
              mt: { xs: 8, md: 0 },
            }}
          >
            <Header />
            <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'flex-start' , width: '100%', mb: 2 }}  >
              <Button variant="contained" onClick={handleCrear} sx={{ textTransform: 'none' }}> Crear Caso</Button> 
            </Grid>
            <Grid container spacing={2}>
              {isLoading ? 
                Array.from({ length: 20 }, (_, index) => {
                  return (
                    <Grid key={`grid-load-${index}`} xs={12} sm={6} md={3} sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Card sx={{ width: 400 }}>
                        <Skeleton animation="wave" />
                      </Card>
                    </Grid>
                  )
                }) : null
              }
              {data ? (
                data?.map((item, index) => (
                <Grid key={`grid-${index}`} xs={12} sm={6} md={3} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Card sx={{ width: 400 }}>
                    <Box sx={{ backgroundColor: item.statusColor, padding: '10px 0px 10px 0px', color: 'white', textAlign: 'center' }}>
                      <Typography variant="subtitle1" sx={{ fontWeight: 'bold', textAlign : 'left' }}>
                        {statusColor(item.casoEstatus)}
                      </Typography>
                      <Typography variant="caption" sx={{ fontWeight: 'bold', textAlign : 'right' }}>
                          {item.secuenciaCodigo}
                      </Typography>
                    </Box>
                    <CardContent>
                      <Typography variant="body1" sx={{ mt: 2 }}>
                        {item.casoNombre}
                      </Typography>
                      <Typography variant="subtitle2" color="textSecondary">
                        {item.cateNombre}
                      </Typography>
                      <Typography variant="caption" sx={{ mt: 2, display: 'block' }} color="text.secondary">
                        {item.casoFecha}
                      </Typography>
                      <Divider/>
                      <Typography component="div" sx={{ mt: 2, display: 'flex', align: 'center' }}>
                        <Avatar alt={item.usuarioCreacionNombres} > {item.usuarioCreacionNombres.slice(0, 1).toUpperCase()} </Avatar>
                        <Typography sx={{ m: 1, display: 'block'}}>
                        {item.usuarioCreacionNombres}
                        </Typography>
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Chip
                        label="Ver Detalle"
                        icon={<ArrowForwardIcon />}
                        onClick={() => handleCasoDetalle(item.identificacion)} 
                        clickable
                        color="primary"
                        variant="outlined"
                        sx={{ marginLeft: 'auto' }}
                      />
                    </CardActions>
                  </Card>
                </Grid>
              )) )
            : null
            }
            </Grid>
          </Stack>
        </Box>
      </Box>
  </AppTheme>
  );
}
