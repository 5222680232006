import React from 'react';
import ReactDOM from 'react-dom/client';
import '@fontsource/roboto'; 
/*
import './index.css'; 
*/
import {BrowserRouter} from 'react-router-dom';
import App from './App';
import AuthProvider from "./utilities/context/auth"
import reportWebVitals from './reportWebVitals';
import AuthProvide from './utilities/context/auth';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <AuthProvider>
        <App />
    </AuthProvider>
  </BrowserRouter>
);

reportWebVitals();
