import * as React from 'react';
import {useEffect, useState} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import { alpha } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
// import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import AppNavbar from '../../../components/AppNavbar';
import Header from '../../../components/Header';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import BlockIcon from '@mui/icons-material/Block';
import SideMenu from '../../../components/SideMenu';
import AppTheme from '../../../shared-theme/AppTheme';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
// import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
// import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
// import styles from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import { useAuth } from "../../../utilities/context/auth";
import apiClient from "../../../api/apiClient";
import { checkResponse } from "../../../utilities/requestsHandling";
import {
  chartsCustomizations,
  dataGridCustomizations,
  datePickersCustomizations,
  treeViewCustomizations,

} from '../../../theme/customizations';
import { esES } from '@mui/x-data-grid/locales';
import { DataGrid, GridRowsProp, GridColDef, GridActionsCellItem, DEFAULT_GRID_AUTOSIZE_OPTIONS } from '@mui/x-data-grid';
import { EvStationTwoTone } from '@mui/icons-material';
import { CustomNoRowsOverlay   } from '../../../internals/components/CustomIcons';
import { render } from '@testing-library/react';
import { ListItem, Paper } from '@mui/material';
import RolCreate from './createRol';
import RolEdit from './editRol';

const xThemeComponents = {
  ...chartsCustomizations,
  ...dataGridCustomizations,
  ...datePickersCustomizations,
  ...treeViewCustomizations,
};

export default function Roles(props) {
  const [includeHeaders, setIncludeHeaders] = React.useState(
    DEFAULT_GRID_AUTOSIZE_OPTIONS.includeHeaders,
  );
  const [includeOutliers, setExcludeOutliers] = React.useState(
    DEFAULT_GRID_AUTOSIZE_OPTIONS.includeOutliers,
  );
  const [outliersFactor, setOutliersFactor] = React.useState(
    String(DEFAULT_GRID_AUTOSIZE_OPTIONS.outliersFactor),
  );
  const [expand, setExpand] = React.useState(DEFAULT_GRID_AUTOSIZE_OPTIONS.expand);

  const autosizeOptions = {
    includeHeaders,
    includeOutliers,
    outliersFactor: Number.isNaN(parseFloat(outliersFactor))
      ? 1
      : parseFloat(outliersFactor),
    expand,
  };
  const [listRolesLoading, setListRolesLoading] = useState(true);
  const [listRoles, setListRoles] = useState([]);
  const [rolEdit, setRolEdit] = useState({});
  const [RolCreateOpen, setRolCreateOpen] = useState(false);
  const [RolEditOpen, setRolEditOpen] = useState(false);
  // const rows = [
  //   { id: 1, nombreCompleto: 'David Aquino', position: 'Encargado', correo:'david@si.com.do', area: 'DIDI', division: 'Desarrollo', roles: [{ id: 3, name: 'Leaks', color: '#27FF00' }, { id: 2, name: 'Usuario', color: '#f8d110' }], twofaa: '✅', ultimoAcceso: 'ayer', isActive: true, isConnected: true },
  //   { id: 2, nombreCompleto: 'Erick Perez', position: 'Miembro', correo:'erick@si.com.do', area: 'DGII', division: 'Desarrollo', roles: [{ id: 3, name: 'Leaks', color: '#27FF00' }], twofaa: '✅', ultimoAcceso: '20-01-2025', isActive: false, isConnected: true }
  // ];

  async function fetchRoles(){
    setListRolesLoading(true);
    const listRolesRequest = await apiClient._get('/roles');
    const processedList = listRolesRequest.data.map((item, idx) => ({...item, id: idx+1}));
    setListRoles(processedList);
    setListRolesLoading(false);
  }

  useEffect(() => {
    fetchRoles();
  }, []);

  const handleEditClick = (rol) => {
    setRolEdit(rol);
    setRolEditOpen(true);
  };

  const handleOnCloseCreate = () => {
    fetchRoles();
    setRolCreateOpen(false);
  };

  const handleOnCloseEdit = () => {
    fetchRoles();
    setRolEditOpen(false)
  };
  
  const handleDisable = async(id) => {
    const toggleRol = await apiClient._get(`/roles/estado/${id}`);
    const updatedListRoles = listRoles.map((rol) =>
      rol.rolId === id ? { ...rol, rolEstatus: toggleRol.data } : rol
    );
    setListRoles(updatedListRoles);
  };
  
  const columns = [
    { field: 'id', headerName: '#', width: 150 },
    { field: 'rolNombre', headerName: 'Nombre Rol', width: 200, renderCell: (params) => (
      <>
        <span>{params.row.rolNombre}</span>
      </>
    )},
    { field: 'rolEstatus', headerName: 'Estado', minWidth: 150, display: 'flex', renderCell: (params) => { return params.row.rolEstatus ? '✅' : '❌'}  },
    { field: 'rolColor', headerName: 'Color', width: 150, colSpan: 1, renderCell: (params) => (<Chip
      key={params.id}
      color=''
      // label={rolColor || 'Unknown'}
      sx={{
        margin: '2px',
        // padding: 0.5,
        color: params.row.rolColor ?? '#fff',
        background: params.row.rolColor ?? '#fff',
      }}
    />)},
    // { field: 'division', headerName: 'Division', width: 150 },
    // { field: 'roles', headerName: 'Roles', minWidth: 200, display: 'flex', renderCell: (params) => (<>
    //   {params.row.roles
    //     .map((role, idx) => (
    //         <Chip
    //           key={idx}
    //           color=''
    //           label={role.rolNombre || 'Unknown'}
    //           sx={{
    //             margin: '2px',
    //             // padding: 0.5,
    //             color: role.rolColor,
    //           }}
    //         />
    //     ))
    //   }
    // </>)},
    // { field: 'usuario2fa', headerName: '2FA', width: 150, renderCell: (params) => { return params.row.usuario.usuario2fa ? '✅' : '❌'} },
    // { field: 'usuarioEstatus', headerName: 'Estado', width: 150, renderCell: (params) => { return params.row.usuario.usuarioEstatus ? '✅' : '❌'} },
    // { field: 'ultimoAcceso', headerName: 'Ultimo acceso', width: 150 },
    // { field: 'isConnected', headerName: 'Conectado', width: 150, renderCell: (params) => { return params.row.isConnected ? '✅' : '❌'; }},
    { field: 'actions', headerName: 'Acciones', width: 150, type: 'actions', cellClassName:'actions', getActions: (params) => {
      return [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Editar"
          className="textPrimary"
          onClick={() => handleEditClick(params.row)}
          color="inherit"
        />,
        <GridActionsCellItem
          icon={params.row.rolEstatus ? <BlockIcon /> : <CheckBoxIcon/> }
          label={params.row.rolEstatus ? 'Deshabilitar': 'Habilitar'}
          onClick={() => handleDisable(params.row.rolId)}
          color="inherit"
        />,
      ];
    }},
  ];
  return <AppTheme {...props} theme={xThemeComponents}>
    <CssBaseline enableColorScheme />
    <Box sx={{ display: 'flex' }}>
      <SideMenu />
      <AppNavbar />
      <Box
        component="main"
        sx={(theme) => ({
          flexGrow: 1,
          backgroundColor: theme.vars
            ? `rgba(${theme.vars.palette.background.defaultChannel} / 1)`
            : alpha(theme.palette.background.default, 1),
          overflow: 'auto',
        })}>
        <Stack
            spacing={2}
            sx={{
              alignItems: 'center',
              mx: 3,
              pb: 5,
              mt: { xs: 8, md: 0 },
            }}
          >
            <Header searchStateProp={false} />
            <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
              <Typography variant="h6" sx={{ mb: 2 }}>Listado de roles</Typography>
              <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'flex-start' , width: '100%', mb: 2 }}  >
                <Button variant="contained" onClick={() => setRolCreateOpen(true)} sx={{ textTransform: 'none' }}> Crear Rol</Button> 
              </Grid>
              <DataGrid
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                editMode='row'
                autosizeOptions={autosizeOptions}
                density='comfortable'
                disableColumnMenu={true}
                disableColumnSorting={true}
                rows={listRoles}
                columns={columns}
                loading={listRolesLoading}
                slotProps={{
                  loadingOverlay: {
                    variant: 'linear-progress',
                    noRowsVariant: 'linear-progress',
                  },
                }}
                sx={{
                  '& .MuiDataGrid-cell': {
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',
                  },
                }}
                slots={{ noRowsOverlay: CustomNoRowsOverlay }}
              />
              {RolCreateOpen && <RolCreate open={RolCreateOpen} handleClose={handleOnCloseCreate} />} 
              {RolEditOpen && <RolEdit open={RolEditOpen} handleClose={handleOnCloseEdit} rolEdit={rolEdit}/>}
            </Box>
        </Stack>
      </Box>
    </Box>
  </AppTheme>
};