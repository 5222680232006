import * as React from 'react';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import AnalyticsRoundedIcon from '@mui/icons-material/AnalyticsRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import LogoutIcon from '@mui/icons-material/Logout';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { useNavigate } from 'react-router-dom';
import {useState} from 'react';
import LeakAddIcon from '@mui/icons-material/LeakAdd';
import { useAuth } from "../utilities/context/auth";
import Skeleton from "@mui/material/Skeleton";
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';

const mainListItems = [
  { index: 0, text: 'Inicio', icon: <HomeRoundedIcon />, path: '/', permiso : "DHBD" },
  { index: 1 ,text: 'Casos', icon: <AnalyticsRoundedIcon />, path:'/Casos', permiso : "CASOS" },
  { index: 2, text: 'Personas', icon: <AssignmentRoundedIcon />, path:'/PersonasBuscar' , permiso :"PRS" },
  // { index: 3, text: 'Tareas', icon: <AssignmentRoundedIcon />, path:'/Tareas', permiso : "TRS" },
  { index: 4, text: 'Leaks', icon: <LeakAddIcon />, path:'/LeaksDetalle', permiso : "LEAKS"  },
];

const secondaryListItems = [
  {  index: 5, text: 'Usuarios', icon: <PeopleAltIcon /> , path: '/Usuarios', permiso : "ADUS" },
  {  index: 6, text: 'Roles', icon: <PeopleAltIcon /> , path: '/Roles', permiso: "ADUS" },
];

export default function MenuContent() {
  const { selectedIndex, handleClick, hasPermission, isLoading } = useAuth();

  // if (isLoading) {
  //   return <Stack sx={{ flexGrow: 1, p: 1, justifyContent: 'space-between' }}>
  //     <List dense>
  //       {Array.from({ length: mainListItems.length || 5 },(_, index) => (
  //         <ListItem key={`main-list-skeleton-${index}`} disablePadding sx={{ display: 'block' }}>
  //           <Skeleton id={index} animation="wave" height={40} />
  //         </ListItem>
  //       ))}
  //     </List>
  //     <List dense>
  //       {Array.from({ length: secondaryListItems.length || 5 },(_, index) => (
  //         <ListItem key={`second-list-skeleton-${index}`} disablePadding sx={{ display: 'block' }}>
  //           <Skeleton id={index} animation="wave" height={40} />
  //         </ListItem>
  //       ))}
  //     </List>
  //     </Stack>
  // }
  return (
    <Stack sx={{ flexGrow: 1, p: 1, justifyContent: 'space-between' }}>
      <List dense>
        {mainListItems.map((item, index) => {
          if(hasPermission(item.permiso)) {
            return (
            <ListItem key={item.index} disablePadding sx={{ display: 'block' }}>
              <ListItemButton 
                selected={selectedIndex === item.index}
                onClick={() => handleClick(item.index, item.path)}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem> 
            )
          }
        })}
      </List>

      <List dense>
        {secondaryListItems.map((item, index) => {
          if(hasPermission(item.permiso)) {
            return (
              <ListItem key={item.index} disablePadding sx={{ display: 'block' }}>
                <ListItemButton 
                  selected={selectedIndex === item.index}
                  onClick={() => handleClick(item.index, item.path)}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </ListItem>
            )
          }
          return null;
        })}
      </List>
    </Stack>
  );
}
