import React from 'react';

export default function Ipaddresses() {
  return (
    <div>
      <h1>Ip</h1>
      <p>Esta es la página de ip.</p>
    </div>
  );
}
