import React, { useEffect} from 'react';
import {useParams} from  'react-router-dom';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid2';
import { Avatar } from '@mui/material';
import apiClient from './api/apiClient'
import { useAuth } from './utilities/context/auth';
import { checkResponse } from './utilities/requestsHandling';

export default function Caso() {

  const { id } = useParams();
  const { isLoading, setLoading, showAlert } = useAuth();
  const [data, setData] = React.useState(null);
  const [error, setError] = React.useState(null);


  useEffect( () => {
    setLoading(true);
    setError(null);
    
    const fetchData =  async () => {

    try {
       const respuesta = await apiClient._get(`/casos/completo/${id}/`);
       setData (respuesta.data);
    }
    catch (error)
    {
      if (error.response) {
        showAlert(checkResponse(error), 'error');
      } else if (error.request) {
        showAlert('Error de red: No se recibió respuesta del servidor', 'error');
      } else {
       
        showAlert(error.message, 'error');
      }

      setLoading(false);
    }
    finally{
      setLoading(false);
    }
  }
  fetchData();
  }, []);

  // if (isLoading) return <p>Cargando...</p>;
  // if (error) return <p>{error}</p>;
  if (!data) return <p>No hay datos disponibles.</p>;

  return (
  <Grid container spacing={2}>
    <Card sx={{ maxWidth: 400 , height : 'fit-content' }}>
      <CardContent>
        <Typography variant="subtitle2" color="textSecondary">
          {data?.caso?.cateNombre || "N/A"}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
        <Avatar  sx={{ mr: 1 }}>{data?.caso?.casoNombre.slice(0, 2).toUpperCase() || "?"}</Avatar>
        <Typography variant="body1" sx={{ mt: 2 }}>
          {data?.caso?.casoNombre || "N/A"}
        </Typography>
        </Box>
        <Typography variant="body2" sx={{ mt: 2 }}>
          {data?.caso?.casoDescripcion || "N/A"} 
        </Typography>
        <Typography variant="caption" sx={{ mt: 2, display: 'block' }} color="text.secondary">
          {data?.caso?.casoFecha || "N/A"}
        </Typography>
      </CardContent>
    </Card>

    <Card sx={{ maxWidth: 400 , height : 'fit-content'  }}>
      <CardContent>
        <Typography variant="subtitle2" color="textSecondary">
          Hechos
        </Typography>

        <Typography variant="body2" sx={{ mt: 2 }}>
          asd ad assdkfasdfksdf sdf sdf 
          sdf sdaf asf asdf asdf asdf asdf asdf asdfasdf sdf
          sd fasd
          af as
          df sadfsdf asdf asdf asdf asdf asdf sdf asdaf sdfsdf sdf 
          sdf asdaf asdf asdf asdfzxcvzxvzxcv asdvdv sdvsd sdf sdfsd f
          ds fasdf asdfsdf sdaf dfggsdf assdkfasdfksdf 
        </Typography>
        <Typography variant="caption" sx={{ mt: 2, display: 'block' }} color="text.secondary">
          Santo Domingo
        </Typography>
        <Typography variant="caption" sx={{ mt: 2, display: 'block' }} color="text.secondary"> 
          12/12/2023
        </Typography>
      </CardContent>
    </Card>
  </Grid>
  );
}
