import React from "react";
import { CircularProgress } from "@mui/material";
import "./loadingComponents.css"
// import {FormattedMessage, format} from "react-intl"
// import Swal from "sweetalert2"
// import firebase from "../../Firebase"

const LoadingComponent = ({ isLoading }) => {
  return isLoading ? <div className="loadingComponent"><CircularProgress color="primary" size="3rem" /></div> : null;
};

export default LoadingComponent;