import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { alpha } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import axios from 'axios';
import Stack from '@mui/material/Stack';
import AppNavbar from './components/AppNavbar';
import Header from './components/Header';
import Button from '@mui/material/Button';
import SideMenu from './components/SideMenu';
import AppTheme from './shared-theme/AppTheme';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import apiClient from "./api/apiClient"
import {
  chartsCustomizations,
  dataGridCustomizations,
  datePickersCustomizations,
  treeViewCustomizations,

} from './theme/customizations';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'; 
import Divider  from '@mui/material/Divider';
import PersonaDetalle from './PersonaDetalle';
import { useAuth } from "./utilities/context/auth";

function statusColor(status) {
  const colors = {
    1: 'success',
    2: 'default',
  };

 
  return <Chip label={status === 1 ? 'Abierto': 'Cerrado'} color={colors[status]} size="small" />;
}

const xThemeComponents = {
  ...chartsCustomizations,
  ...dataGridCustomizations,
  ...datePickersCustomizations,
  ...treeViewCustomizations,
};


export default function PersonasResultados(props) {
  const { showAlert, setLoading } = useAuth();
  const location = useLocation();
  const [data, setData] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [token, setToken] = React.useState(null);
  const [show, setShow] = React.useState(false);
  const { caso, documento, nombres, apellido1, apellido2, genero, lugar , inicio, final} = location.state || {};

  const handlePersona = () => {} 

  useEffect( () => {
    setLoading(true);

    const fetchData =  async () => {
      try {
        const resp =  await apiClient._post(`persona`,
          {
            tipoDocumento : 1,
            documento: documento,
            LugarNacimiento : lugar,
            nombres: nombres,
            apellido1: apellido1,
            apellido2: apellido2,
            EdadInicio : inicio,
            EdadFin : final,
            sexo : genero
          }
        );
        
        setData(resp.data);
      }
      catch (error)
      {
        if(error.response.data){
          showAlert(error.response.data, 'error');
          navigate('/PersonasBuscar', {
            state: { caso, documento, nombres, apellido1, apellido2, genero, lugar, inicio, final },
          });
        }
        else if (error.response) {
          showAlert(`Error de servidor: ${error.response.status}`, 'error');
        } else if (error.request) {
          showAlert('Error de red: No se recibió respuesta del servidor', 'error');
        } else {
          showAlert( error.message, 'error');
        }

        setLoading(false);
      }
      finally {
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  const [casoValor, setCasoValor] = useState('');
  const navigate = useNavigate();
  const handleCasoDetalle = (id) => {
    navigate(`/NavPrincipal/Caso/${id}`);
  };

  return (
  <AppTheme {...props} themeComponents={xThemeComponents}>
      <CssBaseline enableColorScheme />
      <Box sx={{ display: 'flex' }}>
        <SideMenu />
        <AppNavbar />
        <Box
          component="main"
          sx={(theme) => ({
            flexGrow: 1,
            backgroundColor: theme.vars
              ? `rgba(${theme.vars.palette.background.defaultChannel} / 1)`
              : alpha(theme.palette.background.default, 1),
            overflow: 'auto',
          })}
        >
          <Stack
            spacing={2}
            sx={{
              alignItems: 'center',
              mx: 3,
              pb: 5,
              mt: { xs: 8, md: 0 },
            }}
          >
            <Header />
            <Grid container spacing={2}>
              {data ? (
                data?.map((item, index) => (
                <Grid xs={12} sm={6} md={3} key={index} sx={{ display: 'flex', justifyContent: 'center' }}>  
                  <Card sx={{ width: 400 }}>
                    <CardContent>
                      <Box sx={{ display: 'flex',  alignItems: 'center'}}>
                        <Box sx={{ mr: 2 }}>
                          <Avatar sx={{ mr: 2 , width: 100, height: 100 }}  
                            src={item.fotoRuta} variant="rounded">
                          </Avatar>
                        </Box>
                        <Box>
                        <Typography variant="subtitle1">
                          {item.nombrecompleto}
                        </Typography>
                        <Typography variant="subtitle2">
                          {item.documento}
                        </Typography>
                        </Box>
                      </Box>

                      <Typography variant="body2" sx={{ mt: 2 }}>
                        Lugar Nacimiento: {item.lugarNacimiento}<br/>
                        Fecha Nacimiento: {item.fhNacimiento.split("T")[0]}<br/>
                        Edad: {item.edad},&nbsp;&nbsp;{item.genero}<br/>
                      </Typography>
                      <Divider>{index + 1}</Divider>
                    </CardContent>
                    <CardActions>
                      <PersonaDetalle persona={item}></PersonaDetalle>
                    </CardActions>
                  </Card>
                </Grid>
              )))
            : null}
            </Grid>
          </Stack>
        </Box>
      </Box>
    </AppTheme>
  );
}
