import ProtectedRoute from './utilities/protectedRoute';
import Casos from './Casos';
import Dashboard from './Dashboard';
import LeaksDetalle from './LeaksDetalle';
import PersonasBuscar from './PersonasBuscar';
import Usuarios from './components/managment/users/users';

const rutas = [
  { path: "/", name: "DashBoard", element: <ProtectedRoute> <Dashboard /> </ProtectedRoute> },
  { path: "/Casos", name: "Casos", element: <ProtectedRoute> <Casos /> </ProtectedRoute> },
  { path: "/PersonasBuscar", name: "Busqueda de Personas", element: <ProtectedRoute> <PersonasBuscar /> </ProtectedRoute> },
  { path: "/Usuarios", name: "Usuarios", element: <ProtectedRoute> <Usuarios /> </ProtectedRoute> },
  { path: "/LeaksDetalle", name: "Leaks DGII", element: <ProtectedRoute> <LeaksDetalle /> </ProtectedRoute> },
];
export default rutas;
