import * as React from 'react';
import { styled } from '@mui/material/styles';
import Divider, { dividerClasses } from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MuiMenuItem from '@mui/material/MenuItem';
import { paperClasses } from '@mui/material/Paper';
import { listClasses } from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon, { listItemIconClasses } from '@mui/material/ListItemIcon';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import MenuButton from './MenuButton';
import { useNavigate } from 'react-router-dom';
import ResetearClave from '../resetearClave';

const MenuItem = styled(MuiMenuItem)({
  margin: '2px 0',
});

export default function OptionsMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openResetearClave, setOpenResetearClave] = React.useState(false);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleOpen = () => { 
    setOpenResetearClave(true);
  };
  const handleClose = () => {
    setOpenResetearClave(false);
  };

  const handleCerrar = (event) => { 
    setAnchorEl(null); 
  };


  const handleSalir = () => {
    localStorage.clear();
    navigate('/sign-in/SignIn');
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCambiar = (event) => {
    setAnchorEl(event.currentTarget);
  };


  return (
    <React.Fragment>
      <MenuButton
        aria-label="Open menu"
        onClick={handleClick}
        sx={{ borderColor: 'transparent' }}
      >
        <MoreVertRoundedIcon />
      </MenuButton>
      <Menu
        anchorEl={anchorEl}
        id="menu"
        open={open}
        onClose={handleCerrar}
     
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        sx={{
          [`& .${listClasses.root}`]: {
            padding: '4px',
          },
          [`& .${paperClasses.root}`]: {
            padding: 0,
          },
          [`& .${dividerClasses.root}`]: {
            margin: '4px -4px',
          },
        }}
      >
         <ResetearClave open={openResetearClave} handleClose={handleClose} />
          <MenuItem onClick= {() => {
            handleOpen();
          }} >Cambiar Clave</MenuItem>
       
        <Divider />
        <MenuItem
          onClick={handleCerrar}
          sx={{
            [`& .${listItemIconClasses.root}`]: {
              ml: 'auto',
              minWidth: 0,
            },
          }}
        >
          <ListItemText onClick={handleSalir}>Salir</ListItemText>
          <ListItemIcon>
            <LogoutRoundedIcon fontSize="small" />
          </ListItemIcon>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
